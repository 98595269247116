<template lang="pug">
.narrow--content
  h3.text-align-center(v-if="!standalone") Sonstiges (optional)
  p.text-align-center(v-if="!standalone") Abschließend können Sie sonstige Wünsche hinzufügen.
  fieldset
    legend(v-if="!standalone") Zusatz
    div(v-for="e in product.extras" v-if="product" style="margin-bottom: 10px;")
      .input-wrapper
        input(type='hidden' :name='"order_item["+e.key_name+"_product_id]"' :value="e.id" v-if="_form[e.key_name] && standalone")
        input(type='hidden' :name='"order_item["+e.key_name+"_product_id]"' value="" v-else)
        input(type='checkbox' :name='"order_item["+e.key_name+"_product_id]"' :id='e.key_name' v-model="form[e.key_name]" :value="e.id" :disabled="_form[e.key_name] && standalone")
        .custom-toggle
        label(:for='e.key_name')
          | {{e.name}} 
          .tooltip(v-if="e.description")
            button.btn.is--icon(type="button")
              svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
                path(d='M11.25 16.75H12.75V11H11.25V16.75ZM12 9.28845C12.2288 9.28845 12.4207 9.21105 12.5755 9.05625C12.7303 8.90145 12.8077 8.70963 12.8077 8.48078C12.8077 8.25194 12.7303 8.06012 12.5755 7.9053C12.4207 7.7505 12.2288 7.6731 12 7.6731C11.7711 7.6731 11.5793 7.7505 11.4245 7.9053C11.2697 8.06012 11.1923 8.25194 11.1923 8.48078C11.1923 8.70963 11.2697 8.90145 11.4245 9.05625C11.5793 9.21105 11.7711 9.28845 12 9.28845ZM12.0016 21.5C10.6877 21.5 9.45268 21.2506 8.29655 20.752C7.1404 20.2533 6.13472 19.5765 5.2795 18.7217C4.42427 17.8669 3.74721 16.8616 3.24833 15.706C2.74944 14.5504 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45268 3.248 8.29655C3.74667 7.1404 4.42342 6.13472 5.27825 5.2795C6.1331 4.42427 7.13834 3.74721 8.29398 3.24833C9.44959 2.74944 10.6844 2.5 11.9983 2.5C13.3122 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8652 4.42342 18.7205 5.27825C19.5757 6.1331 20.2527 7.13834 20.7516 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5765 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0016 21.5ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76664 19.225 7.87498 17.675 6.32498C16.125 4.77498 14.2333 3.99998 12 3.99998C9.76664 3.99998 7.87498 4.77498 6.32498 6.32498C4.77498 7.87498 3.99998 9.76664 3.99998 12C3.99998 14.2333 4.77498 16.125 6.32498 17.675C7.87498 19.225 9.76664 20 12 20Z')
            .tooltip-wrapper
              button.btn.is--tertiary.is--toggle.is--icon(type="button")
                svg(width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
                  path(d='M6.40002 18.6538L5.34619 17.6L10.9462 12L5.34619 6.40002L6.40002 5.34619L12 10.9462L17.6 5.34619L18.6538 6.40002L13.0538 12L18.6538 17.6L17.6 18.6538L12 13.0538L6.40002 18.6538Z')
              span(v-html="e.description" style="font-size: .875em;line-height: 1.5;")
          div(style="margin-top: -5px; margin-bottom: 3px;"): small + {{toCurrency(e.price)}} {{e.key_name != "parking" ? "p.P." : ""}} {{e.key_name === "card" ? " (zzgl. Postversand + 3,90 € je Bestellung)" : ""}}
      .counter(:class="form[e.key_name] ? '' : 'is--hidden'" v-if="e.countable")
        button.btn.is--minus.is--icon.is--inverted(type='button' @click="minus(e.key_name)" v-if="!(_form[e.key_name] && standalone)")
          svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
            path(d='M5.5 12.7499V11.25H18.5V12.7499H5.5Z')
        .input-wrapper
          label.is--floating.is--active(:for='e.key_name+"_count"') Anzahl
          input(type='number' :name='"order_item["+e.key_name+"_count]"' :id='e.key_name+"_count"' min='1' :max='count' step='1' v-model="form[e.key_name+'_count']" :disabled="_form[e.key_name] && standalone" v-if="form[e.key_name] || !standalone")
        button.btn.is--plus.is--icon.is--inverted(type='button' @click="add(e.key_name)"  v-if="!(_form[e.key_name] && standalone)")
          svg(fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg')
            path(d='M11.25 12.75H5.5V11.25H11.25V5.5H12.7499V11.25H18.5V12.75H12.7499V18.5H11.25V12.75Z')
  .input-wrapper
    label(for='final_message') Nachricht 
  .input-wrapper
    textarea(name='order_item[final_message]' id='final_message' rows='5' v-model="final_message")
  button#submit.btn.is--inverted(type='submit')
    span(v-if="standalone") Absenden
    span(v-else) Zum Warenkorb hinzufügen
</template>

<script>
export default {
  components: {
  },
  props: [
    "settings",
    "product",
    "count",
    "order",
    "standalone"
  ],
  data () {
    return {
      _form: {},
      form: {},
      final_message: ""
    }
  },
  mounted () {
  },
  watch: {
    product(){
      if (!this.product) return false
      if (this.order.final_message) this.final_message = this.order.final_message
      this.form = {}
      this.product.extras.map(x => {
        if (this.order[x.key_name+"_product_id"]) this.form[x.key_name] = true
      })
      this.product.extras.filter(x => x.countable).map(x => {
        if (this.order[x.key_name+"_count"]){
          this.form[x.key_name+"_count"] = this.order[x.key_name+"_count"]
        } else {
          this.form[x.key_name+"_count"] = 1
        }
      })
      this._form = JSON.parse(JSON.stringify(this.form))
    }
  },
  computed: {
  },
  methods: {
    add(name){
      if (this.form[name+"_count"] == this.count) return false
      this.form[name+"_count"]++ 
    },
    minus(name){
      if (this.form[name+"_count"] == 0) return false
      this.form[name+"_count"]--
    },
    toCurrency(x){
      return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(x)
    }

  }
}
</script>
